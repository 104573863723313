import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <rect width={48} height={48} rx={12} fill="#fff" />
      <path
        d="M14.482 14.812c-.684 0-1.301-.68-1.301-1.431 0-1.732 3.971-3.014 8.49-3.014.41 0 .684 0 .684-.375 0-.679-1.369-.904-1.369-2.034 0-1.205 1.3-1.958 2.944-1.958 1.643 0 2.943.753 2.943 1.958 0 1.13-1.369 1.281-1.369 2.034 0 .226.206.376.685.376 4.518 0 8.557 1.205 8.557 3.013 0 .753-.547 1.43-1.3 1.43H14.482zm20.47 6.853c1.643 1.13 2.259 3.163 1.985 5.046l-1.916 11.372C34.609 40.344 32.829 42 30.706 42H17.289c-2.122 0-3.902-1.656-4.314-3.917L11.06 26.711a5.35 5.35 0 01.341-2.822 4.84 4.84 0 011.713-2.149c.958-.753 1.164-2.033 0-2.485-1.643-.678-1.301-2.861.342-2.861h9.995c1.574 0 3.492 1.28 3.492 5.422 0 4.142-1.78 3.765-1.78 6.703 0 1.28.958 2.636 2.121 2.787 1.986.226 3.15-.603 3.15-3.615 0-3.088-1.643-4.519-1.643-7.08 0-3.088 1.78-4.293 2.944-4.293h2.875c1.644 0 1.986 2.184.343 2.862-1.164.527-.958 1.807 0 2.485z"
        fill="#5AB8FF"
      />
    </svg>
  )
}

export default SvgComponent
